* {
    padding : 0;
    margin : 0;
    font-family: 'Roboto', sans-serif;
    transition: 0.5s;
}

html {
    --color-blue: #2D92DA;
    --color-blue-dark: #06b;
    --color-blue-light: #86b7da;
    --color-black: #141414;
    --color-bg: white;
    --color-input: #e0e0e0;
    --color-text: black;
}

html[data-theme="dark"] {
    --color-bg:  #1B1B30;
    --color-input: #36344A;
    --color-text: white;
    --color-blue-light: #5c8099;
}

#root {
    height:100vh;
    width:100vw;
    background-color:var(--color-black);
    overflow:hidden;
}

*::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  *::-webkit-scrollbar {
    border-radius: 10px;
    width: 10px;
    height: 8px;
    background-color: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-blue);
}